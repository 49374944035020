import React from 'react'
import { useEffect } from 'react';
import { connect } from "react-redux";
import { Row, Col, Card } from 'react-bootstrap';
import { fetchEVSEStatusAction, fetchChargingStatusAction } from '../../../../store/actions/insightAction';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./chargerStats.css"
import { useTranslation } from 'react-i18next';

function ChargerStatus(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.getEvseStatus();
        props.getTotalCharging();
    }, []);

    const all = "All";
    const available = "Available";
    const in_use = "In use";
    const discovery = "Discovery";
    const coming_soon = "Coming soon"
    const out_of_service = "Maintenance"
    const faulted = "Faulted"
    // console.log(props.evse_charger_status);
    const percentage = (partialValue, totalValue) => {
        // console.log("percentage", Math.round((100 * partialValue) / totalValue));
        return Math.round((100 * partialValue) / totalValue);
    }

    const TotalPercentage = percentage(props.evse_charger_status.total, props.evse_charger_status.total)
    const AvailablePercentage = percentage(props.evse_charger_status.available, props.evse_charger_status.total)
    const InUsePercentage = percentage(props.evse_charger_status.in_use, props.evse_charger_status.total)
    const ComingsoonPercentage = percentage(props.evse_charger_status.coming_soon, props.evse_charger_status.total)
    const OutofServicePercentage = percentage(props.evse_charger_status.out_of_service, props.evse_charger_status.total)
    const UnavailablePercentage = percentage(props.evse_charger_status.unavailable, props.evse_charger_status.total)
    const FaultedPercentage = percentage(props.evse_charger_status.faulted, props.evse_charger_status.total)
    // console.log("UnavailablePercentage", props.evse_charger_status)

    return (
        <>
            <Row>
                <Col xs={12} md={6} className="mt-3">
                    <Card className='statuscard'>
                        <a href={`/dashboard/insight/evse/chargerstats/${all}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
                            <div style={{ textAlign: "left" }}>
                                <p>
                                    <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/TotalCharger.svg'} alt="charging" />
                                    &nbsp;{t("Total Charger/Connector")}
                                </p>
                                <span className="count"> &nbsp;{props.evse_charger_status && props.evse_charger_status.total} / {props.evse_charger_status && props.evse_charger_status.connectors_total} </span>
                                <span style={{ float: "right", marginTop: "-20px" }}>
                                    <div style={{ width: "50px" }} >
                                        <CircularProgressbar value={TotalPercentage} text={`${TotalPercentage}%`} strokeWidth={5}
                                            styles={buildStyles({
                                                textColor: "#0D6EFD",
                                                pathColor: "#0D6EFD",
                                            })}
                                        />
                                    </div>
                                </span>
                            </div>
                        </a>
                    </Card>
                </Col>
                <Col xs={12} md={6} className="mt-3">
                    <Card className='statuscard'>
                        <a href={`/dashboard/insight/evse/chargerstats/${available}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
                            <div style={{ textAlign: "left" }}>
                                <p>
                                    <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/AvailableCharger.svg'} alt="charging" />
                                    &nbsp;{t("Available Charger/Connector")}
                                </p>
                                <span className="count"> &nbsp;{props.evse_charger_status && props.evse_charger_status.available} / {props.evse_charger_status && props.evse_charger_status.connectors_available_count}</span>
                                <span style={{ float: "right", marginTop: "-20px" }}>
                                    <div style={{ width: "50px" }} >
                                        <CircularProgressbar value={AvailablePercentage} text={`${AvailablePercentage}%`} strokeWidth={5}
                                            styles={buildStyles({
                                                textColor: "#198754",
                                                pathColor: "#198754",
                                            })}
                                        />
                                    </div>
                                </span>
                            </div>
                        </a>
                    </Card>

                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="mt-3">
                    <Card className='statuscard'>
                        <a href={`/dashboard/insight/evse/chargerstats/${in_use}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
                            <div style={{ textAlign: "left" }}>
                                <p>
                                    <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/InUseCharger.svg'} alt="charging" />
                                    &nbsp;{t("In Use Charger/Connector")}
                                </p>
                                <span className="count"> &nbsp;{props.evse_charger_status && props.evse_charger_status.in_use} / {props.evse_charger_status && props.evse_charger_status.connectors_in_use_count} </span>
                                <span style={{ float: "right", marginTop: "-20px" }}>
                                    <div style={{ width: "50px" }} >
                                        <CircularProgressbar value={InUsePercentage} text={`${InUsePercentage}%`} strokeWidth={5}
                                            styles={buildStyles({
                                                textColor: "#6C757D",
                                                pathColor: "#6C757D",
                                            })}
                                        />
                                    </div>
                                </span>
                            </div>
                        </a>
                    </Card>

                </Col>
                <Col xs={12} md={6} className="mt-3">
                    <Card className='statuscard'>
                        <a href={`/dashboard/insight/evse/chargerstats/${coming_soon}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
                            <div style={{ textAlign: "left" }}>
                                <p>
                                    <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/ComingsoonCharger.svg'} alt="charging" />
                                    &nbsp; {t("Coming soon Charger/Connector")}
                                </p>
                                <span className="count">&nbsp; {props.evse_charger_status && props.evse_charger_status.coming_soon}/{props.evse_charger_status && props.evse_charger_status.connectors_coming_soon_count} </span>
                                <span style={{ float: "right", marginTop: "-20px" }}>
                                    <div style={{ width: "50px" }} >
                                        <CircularProgressbar value={ComingsoonPercentage} text={`${ComingsoonPercentage}%`} strokeWidth={5}
                                            styles={buildStyles({
                                                textColor: "#0DCAF0",
                                                pathColor: "#0DCAF0",
                                            })}
                                        />
                                    </div>
                                </span>
                            </div>
                        </a>
                    </Card>

                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="mt-3">
                    <Card className='statuscard'>
                        <a href={`/dashboard/insight/evse/chargerstats/${out_of_service}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
                            <div style={{ textAlign: "left" }}>
                                <p>
                                    <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/OutofOrderCharger.svg'} alt="charging" />
                                    &nbsp;{t("Out of order Charger/Connector")}
                                </p>
                                <span className="count"> &nbsp;{props.evse_charger_status && props.evse_charger_status.out_of_service} / {props.evse_charger_status && props.evse_charger_status.connectors_out_of_service_count} </span>
                                <span style={{ float: "right", marginTop: "-20px" }}>
                                    <div style={{ width: "50px" }} >
                                        <CircularProgressbar value={OutofServicePercentage} text={`${OutofServicePercentage}%`} strokeWidth={5}
                                            styles={buildStyles({
                                                textColor: "#FFC107",
                                                pathColor: "#FFC107",
                                            })}
                                        />
                                    </div>
                                </span>
                            </div>
                        </a>
                    </Card>
                </Col>
                <Col xs={12} md={6} className="mt-3">
                    <Card className='statuscard'>
                        <a href={`/dashboard/insight/evse/chargerstats/${faulted}`} style={{ textDecoration: "none", color: "var(--text-primary)" }}>
                            <div style={{ textAlign: "left" }}>
                                <p>

                                    <img src={process.env.PUBLIC_URL + '/images/insight/chargerStatus/unavailableCharger.svg'} alt="charging" />
                                    &nbsp;{t("Faulted Charger/Connector")}
                                </p>
                                <span className="count"> &nbsp;{props.evse_charger_status && props.evse_charger_status.faulted} / {props.evse_charger_status && props.evse_charger_status.connectors_faulted_count} </span>
                                <span style={{ float: "right", marginTop: "-20px" }}>
                                    <div style={{ width: "50px" }} >
                                        <CircularProgressbar value={UnavailablePercentage} text={`${UnavailablePercentage}%`} strokeWidth={5}
                                            styles={buildStyles({
                                                textColor: "#DC3545",
                                                pathColor: "#DC3545",
                                            })}
                                        />
                                    </div>
                                </span>
                            </div>
                        </a>
                    </Card>

                </Col>
            </Row>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.insight.isLoading,
        evse_charger_status: state.insight.evse_charger_status,
        total_sesion: state.insight.charging_total_status,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getEvseStatus: () => dispatch(fetchEVSEStatusAction()),
        getTotalCharging: () => dispatch(fetchChargingStatusAction()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChargerStatus)
