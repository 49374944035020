import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from 'react-i18next';

// Create Document Component
const RevenueVendorInvoiceDocument = (props) => {
    const { t } = useTranslation();
    const gstNumber = process.env.REACT_APP_CLIENT_GST_NO;

    const generatePDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 42;
        const doc = new jsPDF(orientation, unit, size);
        const gst_number = "GST Number: " + gstNumber;

        doc.setFontSize(12);
        //   const org_name=props.org=="All"?"All":props.org
        //   const {organisations}=props.orgdata
        //   const organisation = organisations && organisations.filter(or => or.name == org_name) 
        // const {address,city,email,phone}=organisation[0]
        const range = "Date range: " + props.start.toLocaleString() + " to " + props.end.toLocaleString()
        //   const title ="Organisation: "+ org_name;
        // const org_add="Address: "+address;
        // const org_city="City: "+city
        // const org_email="Email: "+email
        // const org_ph="Phone: "+phone.toString()
        //   const v_amt=(props.all.total_energy *0.3)
        //   const tax=((v_amt * 15) / 100)
        //   const org_unit="Total kWh: "+props.all.total_energy 
        //   const org_amt="Total Amount: "+ v_amt.toFixed(2) 
        //   const org_tax="Tax Amount: "+ tax.toFixed(2)
        //   const total_amt="Total Payable(Amount + Tax): "+"$"+(v_amt + tax).toFixed(2)



        const headers = [["Location", "Operator", "City", "Per Unit kWh",
            "Total Amount", "Unit Consumed", "Client Revenue", "Self Revenue"]];
        const data = props.revenue_sharing_report && props.revenue_sharing_report.data.map(item => [item.ID.location, item.ID.operator, item.ID.city, item.ID.per_unit_kwh, item.total_amount, item.kwh, item.ID.client_revenue_share, item.ID.self_revenue_share]);
        // console.log("data from revenue vendor document", data)

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        // doc.text(title, marginLeft, 40);
        // doc.text(org_add, marginLeft, 55);
        // doc.text(org_city, marginLeft, 70);
        // doc.text(org_email, marginLeft, 85);
        // doc.text(org_ph, marginLeft, 100); 
        doc.text(range, marginLeft, 115);
        doc.text(gst_number, marginLeft, 100);

        doc.text(range, marginLeft, 30);
        // doc.text(org_unit, marginLeft, 135);
        // doc.text(org_amt, marginLeft, 155);
        // doc.text(org_tax, marginLeft, 175);
        // doc.text(total_amt, marginLeft, 195).setFillColor(25, 135, 84, 0);
        doc.autoTable(content);
        doc.save(props.start.toISOString().substr(0, 10) + "_" + props.end.toISOString().substr(0, 10) + `.pdf`)
    }

    return (
        <div>
            <button onClick={generatePDF} className='pdfbutton' type="button">{t("PDF(Partner)")}</button>
        </div>
    )
}

export default RevenueVendorInvoiceDocument;