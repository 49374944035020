import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "react-dates/initialize";
import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import MyDocument from "../../pdf/invoice";
import { FaEye } from "react-icons/fa";
import VendorInvoiceDocument from "../../pdf/vendor_invoice";
import {
  fetchMyReportCardCountAction,
  fetchMyReportReport,
  fetchReportCsvData,
  reportPercentageChange,
} from "../../../../store/actions/reportActions";
import "../report.css";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import CustomReportPaginationTable from "./customReportPaginationTable";


function AllReportComponent(props) {
  const { t } = useTranslation();
  const currency = process.env.REACT_APP_CURRENCY;
  const oneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week
  const currentDate = new Date(); // Get the current date and time
  const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Get tomorrow's date
  const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds)); // Set startDate to one week before currentDate
  const [endDate, setEndDate] = useState(tomorrowDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { role } = JSON.parse(localStorage.getItem('user'));
  const [org, setOrg] = useState("All");

  // console.log("start date", startDate)
  // console.log("end date", endDate)

  const handleChange = (e) => {
    const data = e.value;
    setOrg(data);
  };

  const options = [
    { value: "All", label: "All" },
    ...(props.orgdata && props.orgdata.organisations ? props.orgdata.organisations.map(item => ({
      value: item.name,
      label: item.name
    })) : [])
  ];

  const newCardCount = props.reportCountRes && props.reportCountRes
  // console.log("newCardCount", newCardCount);

  const data = props.all && props.all.data;
  const reportCount = newCardCount && newCardCount.report_count;
  const defaultPageSize = 10;
  const pageCount = Math.ceil(reportCount / defaultPageSize)
  console.log("data", data);

  useEffect(() => {
    props.fetchPercentageChange();
    props.list(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org, currentPage, pageSize);
    props.csvDataList(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org, 1, 10000000);
    props.fetchReportCardCount(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org);

  }, [currentPage, pageSize]);


  const Total_session_percentage = props.percentagechange && props.percentagechange.total_session_percentage
  const Total_session_change_symbol = props.percentagechange && props.percentagechange.session_change_symbol

  const Total_energy_percentage = props.percentagechange && props.percentagechange.total_energy_percentage
  const Total_energy_change_symbol = props.percentagechange && props.percentagechange.energy_change_symbol

  const Total_revenue_percentage = props.percentagechange && props.percentagechange.total_revenue_percentage
  const Total_revenue_change_symbol = props.percentagechange && props.percentagechange.revenue_change_symbol

  const Total_completed_session_percentage = props.percentagechange && props.percentagechange.total_session_completed_percentage
  const Total_completed_session_symbol = props.percentagechange && props.percentagechange.total_session_completed_change_symbol

  const loadNewData = () => {
    props.list(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org, currentPage, pageSize);
    props.csvDataList(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org, 1, 10000000);
    props.fetchReportCardCount(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org);
  };


  const csvData = props.csvDataRes && props.csvDataRes.data;
  console.log("csvData: ", csvData);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "charging_id", id: "charging_idd" },
      { Header: "Date", accessor: "start_date" },

      { Header: "Name", accessor: "customer_name" },
      { Header: "Tag ID", accessor: "idtag" },
      { Header: "Customer No.", accessor: "mobile" },
      { Header: "Station Name", accessor: "stationid" },
      { Header: "ChargeBox ID", accessor: "evse_id" },
      { Header: "Connector ID", accessor: "portno" },
      { Header: "Operator", accessor: "operator" },
      { Header: "City", accessor: "city" },
      { Header: "Start time", accessor: "start_time" },
      {
        Header: 'End time',
        accessor: 'stop_time',
        Cell: (cell) => {
          const item = cell?.item
          const status = item.status
          const end_time = item.stop_time;
          // console.log("status:", status);
          if (status === 'Charging') {
            return (
              <>
                <div>
                  {''}
                </div>

              </>

            );
          } else {
            return (
              <>
                <div>
                  {end_time}
                </div>

              </>

            );
          }
        },
      },
      { Header: "Charging time", accessor: "consume_time" },
      { Header: "Status", accessor: "status" },
      {
        Header: 'Units',
        accessor: 'unit',
        Cell: (cell) => {
          // console.log("item:", item);
          return (
            <>
              <div>
                kWh/minute/flat
              </div>
            </>
          );
        },
      },
      {
        Header: 'Unit Price',
        accessor: 'unitprice',
        Cell: (cell) => {
          const d = cell?.item
          // console.log("d:", d);
          return (
            <>
              <div>
                {d.kwh_price}/{d.time_price}/{d.flat_price}
              </div>

            </>

          );
        },
      },
      { Header: "Total kWh", accessor: "kwh" },
      { Header: "Idle Time", accessor: "idle_consumedTime" },
      { Header: "Idle Price", accessor: "idle_calculated_price" },
      {
        Header: "Is Free charging?",
        accessor: "free_charging",
        Cell: (cell) => {
          const d = cell?.item
          return (
            <>
              <div>
                {d.free_charging ? 'Yes' : 'No'}
              </div>
            </>
          )
        }
      },
      {
        Header: "Charging Amount",
        accessor: "charging_amount",
        Cell: (cell) => {
          const d = cell?.item
          return (
            <>
              <div>
                {d.charging_amount.toFixed(2)}
              </div>
            </>
          )
        }
      },
      { Header: "Tax Amount", accessor: "tax_amount" },
      { Header: "Promo Discount", accessor: "promo_discount" },
      { Header: "Total Amount", accessor: "total_amount" },
      {
        Header: "Actions",
        id: 'charging_id_action',
        accessor: "charging_id",
        Cell: (cell) => {
          // console.log(cell)
          const charging_id = cell.cell;
          const tag_id = cell.item?.idtag;
          return (
            <div>
              <span>
                <a
                  href={`/dashboard/report/single/${charging_id}/${tag_id}`}
                  rel="tooltip"
                  title="View"
                >
                  <FaEye />
                </a>
              </span>
            </div >
          );
        },
      },
    ],
    []
  );

  // Initially visible columns
  const initialVisibleColumns =
    [
      'start_date', 'customer_name', 'mobile', 'free_charging', 'idtag',
      'stationid', 'evse_id', 'portno', 'start_time', 'stop_time', 'consume_time',
      'tax_amount', 'total_amount', 'kwh',
      'charging_amount', 'charging_id', 'status'
    ];

  const headers = [
    { label: "ID", key: "charging_id" },
    { label: "Date", key: "start_date" },
    { label: "Name", key: "customer_name" },
    { label: "Tag ID", key: "idtag" },
    { label: "Customer No.", key: "mobile" },
    { label: "Station Name", key: "stationid" },
    { label: "ChargeBox ID", key: "evse_id" },
    { label: "Connector ID", key: "portno" },
    { label: "Operator", key: "operator" },
    { label: "City", key: "city" },
    { label: "Start time", key: "start_time" },
    { label: "End time", key: "stop_time" },
    { label: "Status", key: "status" },
    { label: "Charging time (HH:MM:SS)", key: "consume_time" },
    { label: "Unit (kWh)", key: "kwh_unit" },
    { label: "Unit kWh Price", key: "kwh_price" },
    { label: "Unit (Minute)", key: "time_unit" },
    { label: "Unit Minute Price", key: "time_price" },
    { label: "Unit (Flat)", key: "flat_unit" },
    { label: "Unit Flat Price", key: "flat_price" },
    { label: "Total kWh", key: "kwh" },
    { label: "Idle Time", key: "idle_consumedTime" },
    { label: "Idle Price", key: "idle_calculated_price" },
    { label: "Free charging?", key: "free_charging" },
    { label: "Charging Amount", key: "charging_amount" },
    { label: "Tax Amount", key: "tax_amount" },
    { label: "Total Amount", key: "total_amount" },
  ];

  // console.log("start date", startDate)
  // console.log("end date", endDate)

  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Session")}</Card.Text>
              <Card.Title>
                <b>
                  {newCardCount.total_session !== 0 ? newCardCount.total_session : 0}
                </b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_session_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_session_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "15%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/reports/sessions.svg"
                    }
                    style={{ "width": "60px" }}
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Energy")}</Card.Text>
              <Card.Title>
                <b>{newCardCount && newCardCount.total_energy !== 0 ? newCardCount.total_energy : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_energy_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_energy_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "10%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/reports/energy.svg"}
                    alt=""
                    style={{ "width": "40px" }}
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard mb-4">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("Revenue")}</Card.Text>
              <Card.Title>
                <b>{currency} {newCardCount && newCardCount.total_revenue !== 0 ? newCardCount.total_revenue : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_revenue_change_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_revenue_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-65px",
                  marginRight: "-8px",
                }}
              >
                {<div style={{ fontSize: "3.5rem", paddingRight: "11px", color: "#2ea8cc80", marginTop: "-7px" }}>
                  {currency}
                </div>}
              </span>
            </div>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>
                {t("Sessions Completed")}
              </Card.Text>
              <Card.Title>
                <b>{newCardCount && newCardCount.total_completed !== 0 ? newCardCount.total_completed : 0}</b>
              </Card.Title>
              <span style={{ fontSize: "12px" }}>
                {Total_completed_session_symbol === "+" ? <img src={process.env.PUBLIC_URL + "/images/admin/profit.svg"} className="profitloss" alt="" /> : <img src={process.env.PUBLIC_URL + "/images/admin/loss.svg"} className="profitloss" alt="" />}
                &nbsp;&nbsp;{Total_completed_session_percentage}% &nbsp;{t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-50px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "10%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/reports/sessionsstats.svg"
                    }
                    alt=""
                    style={{ "width": "70px" }}
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Card className="customercard">
            <Card.Header>
              <Row>
                <Col md={2} sm={12}>
                  {role} {t("Report List")}
                </Col>
                <Col md={10} sm={12}>
                  <div className="row justify-content-end">
                    <div style={{ paddingRight: "10px" }} >
                      <ReactSelect
                        options={options ? options : []}
                        name="org"
                        id="floatingInputGridOrg"
                        placeholder={org}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: 'var(--select-control-background-color-white)', // White
                            borderColor: state.isFocused ? 'var(--select-control-border-color-gray)' : 'var(--select-control-border-color-gray)', // Gray
                            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                            color: 'var(--select-control-text-color-black)', // White text color for the control
                            // height: 'calc(1.5em + 0.75rem + 2px)',
                            // minHeight: 'calc(1.5em + 0.75rem + 2px)',
                            '&:hover': {
                              borderColor: 'var(--select-control-hover-border-color-gray)', // Gray
                            },
                          }),
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            // height: 'calc(1.5em + 0.75rem + 2px)',
                            padding: '0 0.75rem',
                            color: 'var(--select-control-text-color-black)', // White text color
                            width: '11rem',
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            whiteSpace: 'nowrap',
                            color: 'var(--select-placeholder-color-gray)', // Gray placeholder color
                          }),
                          input: (base) => ({
                            ...base,
                            color: 'var(--select-control-text-color-black)', // White text color
                            border: 'none',
                            boxShadow: 'none',
                            '& input': {
                              border: 'none !important',
                              color: 'var(--select-control-text-color-black)', // White text color
                            },
                          }),
                          menu: (base) => ({
                            ...base,
                            backgroundColor: 'var(--select-menu-background-color-black)', // Black
                            color: 'var(--select-menu-text-color-white)', // White text color
                          }),
                          option: (base, state) => ({
                            ...base,
                            backgroundColor: state.isFocused
                              ? 'var(--select-option-hover-background-color-gray)' // Gray on hover
                              : 'var(--select-menu-background-color-black)', // Black background
                            color: state.isSelected
                              ? 'var(--select-option-selected-text-color-white)' // White if selected
                              : 'var(--select-menu-text-color-white)', // White otherwise
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                            },
                          }),
                          multiValue: (base) => ({
                            ...base,
                            backgroundColor: 'var(--multi-value-background-color-gray)', // Gray
                          }),
                          multiValueLabel: (base) => ({
                            ...base,
                            color: 'var(--multi-value-text-color-white)', // White text color
                          }),
                          multiValueRemove: (base) => ({
                            ...base,
                            color: 'var(--multi-value-text-color-white)', // White text color
                            ':hover': {
                              backgroundColor: 'var(--select-option-hover-background-color-gray)', // Gray on hover
                              color: 'var(--multi-value-text-color-white)', // White text color
                            },
                          }),
                          clearIndicator: (base) => ({
                            ...base,
                            color: 'var(--clear-indicator-color-white)', // White
                            ':hover': {
                              color: 'var(--clear-indicator-color-white)', // White on hover
                            },
                            cursor: 'pointer'
                          }),
                          singleValue: (base) => ({
                            ...base,
                            color: 'var(--select-control-text-color-black)', // White text color
                          }),
                        }}
                        onChange={handleChange}
                      />

                    </div>
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <DatePicker
                          className="gray-border calendar"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                      <span style={{ paddingTop: "7px" }}> To </span>
                      <div className="col-auto">
                        <DatePicker
                          className="gray-border calendar"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          showDisabledMonthNavigation
                          dateFormat="dd/MM/yyyy"
                          maxDate={tomorrowDate}
                        />
                      </div>
                    </div>
                    <div className="csvbutton ml-3 ">
                      <button className="btn btn-sm" onClick={loadNewData}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#676767",
                            fontWeight: "600",
                          }}
                        >
                          GO
                        </span>
                      </button>
                    </div>
                    <div className="mt-1">
                      {props.csvDataRes && props.csvDataRes.data != null ? (
                        <CSVLink
                          data={csvData}
                          headers={headers}
                          filename={
                            org +
                            "_" +
                            startDate.toISOString().substr(0, 10) +
                            "_" +
                            endDate.toISOString().substr(0, 10) +
                            `.csv`
                          }
                          target="_blank"
                        // onClick={handleCSVLinkClick}
                        >
                          <i className="fas fa-2x fa-file-csv"></i>
                        </CSVLink>
                      ) : (
                        <i className="fas fa-2x fa-file-csv"></i>
                      )}
                    </div>
                    <Col className="col-md-10 col-sm-12 d-flex justify-content-end mt-3">
                      <div style={{ paddingRight: "15px" }}>
                        {props.csvDataRes && props.csvDataRes.data !== null ? (
                          <MyDocument
                            {...props}
                            org={org}
                            start={startDate}
                            end={endDate}
                          // onClick={loadNewData}
                          ></MyDocument>
                        ) : (
                          <i className="fas fa-file-pdf"></i>
                        )}
                      </div>
                      <div>
                        {props.csvDataRes && props.csvDataRes.data !== null ? (
                          <VendorInvoiceDocument
                            {...props}
                            org={org}
                            start={startDate}
                            end={endDate}
                          // onClick={loadNewData}
                          ></VendorInvoiceDocument>
                        ) : (
                          <i className="fas fa-file-pdf"></i>
                        )}
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <div className="evse">
              {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "450px",
              }}>
                Loading...
              </h3> :
                props.all && props.all.data != null ? (
                  <div>
                    {/* <AdminTable data={data} columns={columns}></AdminTable> */}
                    <CustomReportPaginationTable
                      data={data}
                      allData={csvData}
                      pageCount={pageCount}
                      columns={columns}
                      search={search}
                      setSearch={setSearch}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      filteredData={filteredData}
                      setFilteredData={setFilteredData}
                      loading={props.loading}
                      initialVisibleColumns={initialVisibleColumns}
                      t={t}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "450px",
                    }}
                  >
                    <h3>{t("No Data Found")}</h3>
                  </div>
                )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.report.isLoading,
    all: state.report.all,
    csvDataRes: state.report.fetchReportCsv,
    percentagechange: state.report.percentageChange,
    reportCountRes: state.report.fetchMyReportCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    list: (start, end, org, currentPage, pageSize) => dispatch(fetchMyReportReport(start, end, org, currentPage, pageSize)),
    csvDataList: (start, end, org, currentPage, reportCount) => dispatch(fetchReportCsvData(start, end, org, currentPage, reportCount)),
    fetchReportCardCount: (start, end, org) => dispatch(fetchMyReportCardCountAction(start, end, org)),
    fetchPercentageChange: () => dispatch(reportPercentageChange())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllReportComponent);
