import React, { useMemo, useState, useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { fetchRevenueSharingReport } from "../../../store/actions/reportActions";
import { connect } from "react-redux";
import PureBreadcrumbs from "../breadcrums";
import "react-dates/initialize";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../reacttable/table";
import moment from "moment";
import { CSVLink } from "react-csv";
import MyRevenueDocument from "../pdf/revenue_invoice";
import RevenueVendorInvoiceDocument from "../pdf/revenue_vendor_invoice";
import { useTranslation } from "react-i18next";

function RevenueSharingList(props) {
    const { t } = useTranslation();
    useEffect(() => {
        props.revenueSharingReport(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10))
    }, []);

    const [startDate, setStartDate] = useState(
        new Date(Date.now() - 3600 * 1000 * 720)
    );
    const [endDate, setEndDate] = useState(
        new Date(Date.now() + 3600 * 1000 * 24)
    );
    // const [org, setOrg] = useState("All");

    const loadNewData = () => {
        props.revenueSharingReport(
            startDate.toISOString().substr(0, 10),
            endDate.toISOString().substr(0, 10),
        );
    };
    const data = props.revenue_sharing_report && props.revenue_sharing_report.data
    console.log("revenue data", data)

    const sortedData = data && data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    // console.log("sorted data", sortedData)

    const columns = useMemo(
        () => [

            {
                Header: "Location",
                id: "location",
                accessor: (d) => {
                    return (d.ID.location);
                },
            },
            {
                Header: "City",
                accessor: (d) => {
                    return (d.ID.city);
                },
            },
            {
                Header: "Operator",
                accessor: (d) => {
                    return (d.ID.operator);
                },
            },
            {
                Header: "Start Date",
                id: "start_date",
                accessor: (d) => {
                    return moment(d.start_date).local().format("DD-MM-YYYY");
                },

            },
            {
                Header: "End Date",
                id: "end_date",
                accessor: (d) => {
                    return moment(d.end_date).local().format("DD-MM-YYYY");
                },
            },
            {
                Header: "Per Unit kWh",
                accessor: (d) => {
                    return (d.ID.per_unit_kwh);
                },
            },
            {
                Header: "Unit Consumed",
                accessor: (d) => {
                    return (d.kwh)
                }
            },
            {
                Header: "Client Revenue",
                accessor: (d) => {
                    return (d.ID.client_revenue_share);
                },
            },
            {
                Header: "Regional Revenue",
                accessor: (d) => {
                    return (d.ID.regional_revenue_share);
                },
            },
            {
                Header: "Self Revenue",
                accessor: (d) => {
                    return (d.ID.self_revenue_share);
                },
            },
            {
                Header: "Stripe ID",
                accessor: (d) => {
                    return (d.ID.stripe_account);
                },
            },
            {
                Header: "Total Amount",
                accessor: (d) => {
                    return (d.total_amount);
                },
            },

        ],
        []
    );
    const headers = [
        { label: "Location", key: "ID.location" },
        { label: "Operator", key: "ID.operator" },
        { label: "City", key: "ID.city" },
        { label: "Unit Consumed", key: "kwh" },
        { label: "Per Unit kWh", key: "ID.per_unit_kwh" },
        { label: "Client Revenue", key: "ID.client_revenue_share" },
        { label: "Self Revenue", key: "ID.self_revenue_share" },
        { label: "Total Amount", key: "total_amount" },
    ];

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={4} sm={12}>
                                    {t("Revenue Sharing Report")}
                                </Col>
                                <Col md={8} sm={12}>
                                    <div className="row justify-content-end">
                                        <div className="row justify-content-center">
                                            <div className="col-auto claender">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <span style={{ paddingTop: "7px" }}> To </span>
                                            <div className="col-auto">
                                                <DatePicker
                                                    className="gray-border"
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    showDisabledMonthNavigation
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                        <div className="csvbutton ml-3 ">
                                            <button className="btn btn-sm" onClick={loadNewData}>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#676767",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    GO
                                                </span>
                                            </button>
                                        </div>
                                        <div className="mt-1 ">
                                            {props.revenue_sharing_report && props.revenue_sharing_report.data != null ? (
                                                <CSVLink
                                                    data={data}
                                                    headers={headers}
                                                    filename={`Revenue Sharing Report.csv`}
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-2x fa-file-csv"></i>
                                                </CSVLink>
                                            ) : (
                                                <i className="fas fa-2x fa-file-csv"></i>
                                            )}
                                        </div>
                                        <Col className="col-md-10 col-sm-12 d-flex justify-content-end mt-3">

                                            <div style={{ paddingRight: "15px" }}>
                                                {props.revenue_sharing_report && props.revenue_sharing_report.data != null ? (
                                                    <MyRevenueDocument
                                                        {...props}
                                                        start={startDate}
                                                        end={endDate}
                                                    ></MyRevenueDocument>
                                                ) : (
                                                    <i className="fas fa-file-pdf"></i>
                                                )}
                                            </div>
                                            <div>
                                                {props.revenue_sharing_report && props.revenue_sharing_report.data != null ? (
                                                    <RevenueVendorInvoiceDocument
                                                        {...props}
                                                        start={startDate}
                                                        end={endDate}
                                                    ></RevenueVendorInvoiceDocument>
                                                ) : (
                                                    <i className="fas fa-file-pdf"></i>
                                                )}
                                            </div>
                                        </Col>
                                    </div>

                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                props.revenue_sharing_report && props.revenue_sharing_report.data != null ? (
                                    <div>
                                        <Table data={sortedData} columns={columns}></Table>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3>No Data Found</h3>
                                    </div>
                                )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        revenue_sharing_report: state.report.revenueSharingList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        revenueSharingReport: (start, end) => dispatch(fetchRevenueSharingReport(start, end)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RevenueSharingList);

